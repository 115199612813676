import * as Icon from "@iyk/icons"
import * as React from "react"

import { Await } from "@remix-run/react"
import { useCart } from "./cart-provider.tsx"

export function CartTrigger() {
  const { open, cart } = useCart()

  return (
    <div className="relative flex items-center">
      <button type="button" onClick={open}>
        <Icon.Bag className="size-6" />
      </button>
      <React.Suspense>
        <Await resolve={cart}>
          {(c) =>
            c && c.totalQuantity > 0 ? (
              <span className="absolute text-xs top-0 -right-1.5 select-none tabular-nums">
                {c.totalQuantity}
              </span>
            ) : null
          }
        </Await>
      </React.Suspense>
    </div>
  )
}
